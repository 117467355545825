<template>
  <div class="subsales-booking-detail animatedBox">
    <div class="container fluid">
      <!-- ========================= BOOKING INFO ========================== -->
      <div class="row mxn-1">
        <div class="col-12 px-1">
          <div class="card p-2 sm-p-3 mb-2">
            <div class="d-flex align-items-center mb-4">
              <fd-button class="circle mr-2" @click="goBack">
                <i class="fas fa-chevron-left"></i>
              </fd-button>
              <h1>Booking Details</h1>
            </div>
            <div class="row">
              <!-- Booking date -->
              <div class="col-12 mb-2">
                <div class="d-flex justify-content-between">
                  <display-data
                    label="Booking Date"
                    :content="
                      $dayjs(bookingDetail.createdAt).format('DD-MM-YYYY')
                    "
                  ></display-data>
                  <subsale-purchase-status
                    class="px-3 py-1"
                    :status="bookingDetail.status"
                  ></subsale-purchase-status>
                </div>
              </div>
              <!-- OTP Expired Banner -->
              <div v-if="isOTPExpired" class="otp-expired col-12 my-2">
                OTP is expired, please edit OTP or cancel the booking
              </div>
              <!-- Status for booking -->
              <div class="col-12 mb-3">
                <subsale-booking-status
                  class="mb-2"
                  :status="bookingDetail.booking.status"
                ></subsale-booking-status>
                <div class="row mxn-1">
                  <!-- Signature status -->
                  <div class="col-12 sm-col-6 px-1 mb-3">
                    <div class="bordered-card h-100 p-2">
                      <div class="d-flex align-items-center mb-2">
                        <i class="title-icon fas fa-tasks mr-2"></i>
                        <h5>Sign Status</h5>
                      </div>
                      <check-list-status
                        class="mb-1"
                        status="Buyer Signed"
                        :check="bookingDetail.booking.applicantHasSigned"
                      ></check-list-status>
                      <check-list-status
                        class="mb-1"
                        status="Owner Signed"
                        :check="bookingDetail.booking.ownerHasSigned"
                      ></check-list-status>
                    </div>
                  </div>
                </div>
                <!-- Preview OTP -->
                <div class="bordered-card p-2">
                  <div class="d-flex align-items-center mb-2">
                    <i class="title-icon far fa-file-alt mr-2"></i>
                    <h5>Offer To Purchase</h5>
                  </div>
                  <p
                    v-if="!isOTPExpired && isBookingActive"
                    class="otp-expire-hint mb-2"
                    :class="{ alert: getOTPExpiryDayDiff() < 3 }"
                  >
                    Expire in
                    <span class="font-bold">{{ getOTPExpiryDuration() }}</span>
                  </p>
                  <fd-button
                    v-if="isBookingActive"
                    class="main mr-1"
                    :class="{ bordered: !isOTPExpired }"
                    @click="editOTP"
                    >Edit OTP
                  </fd-button>
                  <fd-button
                    loadingEnabled
                    :isLoading="isPreviewOTPLoading"
                    class="main"
                    @click="previewOTP"
                  >
                    Preview OTP
                  </fd-button>
                </div>
              </div>
            </div>
            <!-- Available Actions -->
            <div v-if="isBookingPhase()" class="row justify-content-end">
              <div v-if="!isOTPExpired">
                <fd-button
                  v-if="isBothPartySigned() && !isBookingCompleted"
                  class="main medium ml-1 my-1"
                  @click="completeBooking"
                  >Complete Booking</fd-button
                >
                <fd-button
                  v-if="isBookingCompleted"
                  class="main medium ml-1"
                  @click="completePurchase"
                >
                  Complete Purchase
                </fd-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mxn-1">
        <div class="col-12 md-col-8 px-1">
          <!-- ================== BUYER & OWNER DOCUMENTS ================== -->

          <div class="card p-2 md-p-3 mb-2">
            <h4 class="mb-3">Submitted Documents</h4>
            <div
              v-if="isBookingPhase() && !isOTPExpired"
              class="row justify-content-end mb-3"
            >
              <fd-button
                v-if="canRequestBuyerResubmit()"
                class="main bordered ml-1 my-1"
                @click="requestBuyerResubmit"
              >
                <i class="fas fa-rotate-right mr-1"></i>
                Request Buyer Resubmit
              </fd-button>
              <fd-button
                v-if="canRequestVendorResubmit()"
                class="main bordered ml-1 my-1"
                @click="requestOwnerResubmit"
              >
                <i class="fas fa-rotate-right mr-1"></i>
                Request Owner Resubmit
              </fd-button>
            </div>
            <Tab v-model="submissionTab" :tabs="submissionTabs"></Tab>

            <submission-documents
              class="mt-3"
              :role="submissionTab == 'OWNER' ? 'Owner' : 'Buyer'"
              :submission="
                submissionTab == 'OWNER'
                  ? bookingDetail.ownerSubmission
                  : bookingDetail.applicantSubmission
              "
            ></submission-documents>
          </div>
          <!-- Cancel Booking -->
          <div v-if="isBookingPhase()" class="row">
            <div class="card text-right p-2 mb-2">
              <fd-button
                class="danger medium bordered ml-1"
                @click="cancelBooking"
              >
                Cancel Booking
              </fd-button>
            </div>
          </div>
          <!-- ======================= TRACKING LOG ======================== -->
          <tracking-table
            v-if="trackingLogs"
            class="tracking card p-2 sm-p-3 w-100 mb-2"
            :data="trackingLogs"
            :isLoading="isTrackingLogLoading"
            :loadingCount="2"
          >
            <template #content="{ row }">
              <h6 class="font-bold mb-1">{{ row.title }}</h6>
              <p>{{ $dayjs(row.updatedAt).format("DD-MM-YYYY") }}</p>
              <p>{{ row.agentContent }}</p>
            </template>
          </tracking-table>
        </div>

        <div class="col-12 md-col-4 px-1">
          <!-- ======================= PROPERTY INFO ======================= -->
          <div class="card mb-2">
            <div v-if="!_.isEmpty(property)" class="row">
              <div
                class="property-main-photo"
                :style="{
                  'background-image': `linear-gradient(to bottom, #00000010, #000000EE),
                  url(${
                    property.mainPhoto.length > 0
                      ? $getFileURL(property.mainPhoto[0])
                      : require(`@/assets/image/placeholder/no-img.png`)
                  })`
                }"
              >
                <div class="d-flex align-items-end h-100 p-2">
                  <div>
                    <h5 class="fg-white mb-1">{{ property.title }}</h5>
                    <p class="fg-white">{{ property.referenceCode }}</p>
                  </div>
                </div>
              </div>
              <div class="p-2 sm-p-3">
                <div class="d-flex align-items-start mb-1">
                  <i class="fas fa-map-marker-alt mr-1"></i>
                  <p style="line-height: 1">
                    {{ getPropertyAddress(property) }}
                  </p>
                </div>
                <div class="d-flex align-items-start mb-1">
                  <i class="fas fa-chart-area mr-1"></i>
                  <p style="line-height: 1">
                    {{ property.landArea }}/{{ property.areaUnitType }}
                  </p>
                </div>
                <div class="d-flex align-items-start mb-1">
                  <i class="fas fa-layer-group mr-1"></i>
                  <p style="line-height: 1">
                    {{ property.builtUpArea }}/{{ property.areaUnitType }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- ========================= BUYER INFO ======================== -->
          <div class="card p-2 sm-p-3 mb-2">
            <h3 class="mb-4">Buyer</h3>
            <div v-if="!_.isEmpty(buyer)" class="d-flex">
              <avatar
                :src="getBuyerAvatar()"
                class="mr-2"
                :name="buyer.name"
              ></avatar>
              <div>
                <h5 class="mb-1">{{ buyer.name }}</h5>
                <p>{{ $getUserPhoneNumber(buyer.user) }}</p>
                <p>{{ buyer.email }}</p>
              </div>
            </div>
          </div>
          <!-- ======================== PAYMENT INFO ======================= -->
          <div v-if="payment" class="card p-2 sm-p-3 mb-2">
            <h3 class="mb-4">Payment</h3>
            <div v-if="!_.isEmpty(payment)">
              <p class="payment-amount mb-1">
                RM{{ numberWithCommas(payment.paymentAmount) }}
              </p>
              <display-data
                label="Payment Method"
                :content="payment.paymentMethod"
              ></display-data>
              <display-data
                v-if="payment.paymentMethod == subsalesPaymentMethod.CHEQUE"
                label="Cheque Number"
                :content="payment.chequeNumber"
              ></display-data>
              <display-data
                v-if="
                  payment.paymentMethod == subsalesPaymentMethod.ONLINE_BANKING
                "
                label="Transaction ID"
                :content="payment.transactionId"
              ></display-data>
              <display-data
                label="Paid At"
                :content="$dayjs(payment.paidAt).format('DD-MM-YYYY')"
              ></display-data>
              <download-files
                v-if="
                  payment.paymentMethod == subsalesPaymentMethod.CASH_DEPOSIT
                "
                label="Deposit Receipt"
                :filenamePrefix="`deposit_receipt_${payment.id}`"
                :downloadItem="payment.depositReceipt"
                @download="downloadReceipt"
              ></download-files>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========================= MODAL: OTP FORM ========================= -->
    <modal v-model="editOTPModal.isShown" fullscreen>
      <fd-form class="card modal-content-wrapper" @submit="updateOTP">
        <modal-body class="p-2 sm-p-3">
          <h3 class="mb-4">Offer To Purchase</h3>
          <otp-form
            :isEdit="true"
            :isLoading="editOTPModal.isLoading"
            :otpData="editOTPModal.otpData"
            @change="
              (data) => {
                this.editOTPModal.otpData = data;
              }
            "
            required
          ></otp-form>
        </modal-body>
        <modal-footer class="row justify-content-end p-2">
          <fd-button class="medium mr-1" @click="editOTPModal.isShown = false"
            >Cancel</fd-button
          >
          <fd-button type="submit" class="main medium">Save</fd-button>
        </modal-footer>
      </fd-form>
    </modal>
  </div>
</template>

<script>
import "@/assets/GlobalStyling/static-css/material-colors/colors.css";
import {
  required,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import { subsalesPurchase as subsaleAPI } from "@/api";
import { downloadFile } from "@/utils/download";
import { getPropertyAddress } from "@/utils/property";
import { numberWithCommas, getFileExtension } from "@/utils/string";
import { getDateDiff } from "@/utils/datetime";
import {
  subsalesPaymentMethod,
  subsalesPurchaseStatus,
  subsalesBookingStatus
} from "@/enums";
import { SubsalesOTPModel } from "@/models";

export default {
  name: "booking-detail",
  components: {
    Avatar: () => import("@/components/GlobalComponents/Avatar"),
    TrackingTable: () => import("@/components/GlobalComponents/TrackingTable"),
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Tab: () => import("@/components/GlobalComponents/FdTabs/TabsIso"),
    CheckListStatus: () =>
      import("@/components/GlobalComponents/List/CheckListStatus"),
    SubsalePurchaseStatus: () =>
      import("@/components/SubsalesBooking/SubsalePurchaseStatus"),
    SubsaleBookingStatus: () =>
      import("@/components/SubsalesBooking/SubsaleBookingStatus"),
    SubmissionDocuments: () =>
      import("@/components/SubsalesBooking/SubmissionDocuments"),
    DownloadFiles: () => import("@/components/GlobalComponents/DownloadFiles"),
    OtpForm: () =>
      import("@/components/GlobalComponents/Shared/Subsales/OTPForm")
  },
  data() {
    return {
      bookingDetail: {
        status: {
          id: ""
        },
        booking: {
          status: { id: "" },
          confirmOtpBefore: ""
        }
      },
      property: {},
      buyer: {},
      payment: {},

      isTrackingLogLoading: false,
      trackingLogs: [],

      submissionTab: "BUYER",
      submissionTabs: [
        { label: "Buyer", name: "BUYER" },
        { label: "Owner", name: "OWNER" }
      ],

      isPreviewOTPLoading: false,
      editOTPModal: {
        isShown: false,
        isLoading: false,
        otpData: {}
      },

      // Validator
      validators: {
        required: required,
        price: price
      },

      // Utils & Enums
      subsalesPaymentMethod,
      subsalesBookingStatus,
      getPropertyAddress,
      numberWithCommas
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    isOTPExpired() {
      let dayDiff = this.getOTPExpiryDayDiff();
      let expired = dayDiff <= 0;

      return expired;
    },
    isBookingCompleted() {
      return (
        this.bookingDetail.booking.status.id == subsalesBookingStatus.COMPLETED
      );
    },
    isBookingActive() {
      let sale = this.bookingDetail;
      return (
        sale.booking.status.id != subsalesBookingStatus.COMPLETED &&
        sale.status.id != subsalesPurchaseStatus.COMPLETED &&
        sale.status.id != subsalesPurchaseStatus.CANCELLED
      );
    }
  },
  methods: {
    async init() {
      await Promise.all([this.getBooking(), this.getTrackingLogs()]);
    },
    goBack() {
      this.$router.replace({ name: "SubsalesBookingList" });
    },
    getBuyerAvatar() {
      return this.buyer.avatar.length > 0
        ? this.$getFileURL(this.buyer?.avatar[0])
        : null;
    },
    isBookingPhase() {
      return this.bookingDetail.status.id == subsalesPurchaseStatus.BOOKING;
    },

    isBothPartySigned() {
      return (
        this.bookingDetail.booking.applicantHasSigned &&
        this.bookingDetail.booking.ownerHasSigned
      );
    },
    canRequestBuyerResubmit() {
      return (
        !this.isBookingCompleted &&
        this.bookingDetail.booking.applicantHasSigned
      );
    },
    canRequestVendorResubmit() {
      return (
        !this.isBookingCompleted && this.bookingDetail.booking.ownerHasSigned
      );
    },
    getOTPExpiryDuration() {
      let expiryDate = this.bookingDetail.booking.confirmOtpBefore;
      return getDateDiff(expiryDate, { full: true, space: true });
    },
    getOTPExpiryDayDiff() {
      let today = this.$dayjs();
      let expiryDate = this.$dayjs(
        this.bookingDetail.booking.confirmOtpBefore,
        "YYYY-MM-DD"
      );

      return expiryDate.diff(today, "days", true);
    },
    async editOTP() {
      // Get OTP Data
      await this.getOTPData();
      // Open OTP Modal
      this.editOTPModal.isShown = true;
    },
    // ============================== API RELATED ==============================
    async getBooking() {
      this.$store.commit("setIsLoading", true);
      try {
        let data = await subsaleAPI.getBooking(this.$route.params.id);

        this.bookingDetail = data;
        this.property = this._.cloneDeep(data.property);
        this.buyer = this._.cloneDeep(data.buyer);
        this.payment = this._.cloneDeep(data.payment);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Failed",
          text: "Failed to get booking data. Please try again later."
        });
        throw error;
      }
    },
    async getTrackingLogs() {
      try {
        this.isTrackingLogLoading = true;
        let data = await subsaleAPI.getTrackingLogs(this.$route.params.id);
        this.trackingLogs = data;
        this.isTrackingLogLoading = false;
      } catch (error) {
        this.isTrackingLogLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch tracking log data, . Please try again later."
        });
      }
    },
    async downloadReceipt(file, label, loading) {
      loading(true);
      let url = this.$getFileURL(file);
      let ext = getFileExtension(file.fileName);
      try {
        await downloadFile(url, label, ext);
        loading(false);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Download failed",
          text: "Failed to download the receipt. Please try again later."
        });
        loading(false);
      }
    },
    async cancelBooking() {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Cancel Booking",
        message:
          "Are you sure you want to cancel this booking? This action cannot be undone.",
        confirmText: "Yes, Cancel Booking",
        cancelText: "No, Continue This Booking"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await subsaleAPI.cancelPurchase(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Booking Cancelled",
            text: "Booking has been cancelled successfully."
          });
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to cancel this booking. Please try again later."
          });
        }
      }
    },

    async getOTPData() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await subsaleAPI.getOTPData(this.$route.params.id);
        this.editOTPModal.otpData = this._.cloneDeep(
          SubsalesOTPModel.getToEdit(data)
        );

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to load OTP data. Please try again later."
        });
      }
    },
    async previewOTP() {
      this.isPreviewOTPLoading = true;
      await subsaleAPI.previewOTP(this.$route.params.id);
      this.isPreviewOTPLoading = false;
    },
    async updateOTP() {
      try {
        this.$store.commit("setIsLoading", true);
        await subsaleAPI.updateOTP(
          this.$route.params.id,
          SubsalesOTPModel.putPayload(this.editOTPModal.otpData)
        );
        await this.getBooking();
        this.$store.commit("setIsLoading", false);
        this.editOTPModal.isShown = false;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update OTP form. Please try again later."
        });
      }
    },
    async requestBuyerResubmit() {
      let confirm = await this.$confirm({
        title: "Request Buyer Resubmit",
        message: "Are you sure you want to request a resubmit for buyer?",
        confirmText: "Confirm Request"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await subsaleAPI.requestBuyerResubmit(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text:
              "Successfully requested for buyer to resubmit documents and signature."
          });
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Something went wrong while requesting for buyer resubmit. Please try again later."
          });
        }
      }
    },
    async requestOwnerResubmit() {
      let confirm = await this.$confirm({
        title: "Request Owner Resubmit",
        message: "Are you sure you want to request a resubmit for owner?",
        confirmText: "Confirm Request"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await subsaleAPI.requestOwnerResubmit(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text:
              "Successfully requested for owner to resubmit documents and signature."
          });
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Something went wrong while requesting for owner resubmit. Please try again later."
          });
        }
      }
    },
    async completeBooking() {
      let confirm = await this.$confirm({
        title: "Complete Booking",
        message: "Are you sure you want to complete the booking?"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await subsaleAPI.completeBooking(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Booking has been mark as completed."
          });
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to complete booking. Please try again later."
          });
        }
      }
    },
    async completePurchase() {
      let confirm = await this.$confirm({
        title: "Complete Purchase",
        message: "Are you sure you want to complete the purchase?",
        confirmText: "Complete Purchase"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await subsaleAPI.completePurchase(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Subsale purchase has been mark as completed."
          });
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Failed to complete the subsale purchase. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.subsales-booking-detail {
  .property-main-photo {
    width: 100%;
    height: 200px;
    background-size: cover;
    color: white;
    background-position: center;
  }

  i.title-icon {
    font-size: 24px;
  }

  .otp-expired {
    background: $color-danger;
    color: white;
    border-radius: 4px;
    padding: 16px 32px;
  }
  .otp-expire-hint {
    font-size: 16px;
    &.alert {
      color: $color-danger;
    }
  }

  .tracking {
    h6 {
      color: #404040;
    }
  }
  .payment-amount {
    font-size: 24px;
    font-weight: bold;
    color: $color-secondary;
  }
}
</style>
